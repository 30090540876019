import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Container } from "reactstrap"
import SEO from "../components/seo"
import marked from "marked"

class ResourcesTemplate extends React.Component {
  render() {
    const { resource } = this.props.data
    console.log("ResourcesTemplate -> render -> resource", resource)

    return (
      <Layout location={this.props.location}>
        <SEO title={resource.title} description={resource.excerpt} />
        {/* <div className="page-headline">
          <div className="container">
            <div className="section-heading text-center">
              <h6 className="font-weight-bold text-uppercase text-white-50 flair">
                Blog
              </h6>
              <h1>
                <strong>{post.title}</strong>
                <br />
              </h1>
              <span className="text-white-50 small">
                <i className="fa fa-calendar-o pr-1" />
                {post.publishedAt}
              </span>
            </div>
          </div>
        </div> */}
        <div className="page-content blog-innerpage">
          <Container>
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="blog-title text-center mb-5">
                  <h1 className="section-title text-dark text-shadow">
                    <strong>{resource.title}</strong>
                    <br />
                  </h1>
                  {/* <span className="text-dark small">
                    <i className="fa fa-calendar-o pr-1" />
                    {resource.publishedAt}
                  </span> */}
                </div>
                <div className="mx-auto text-center mb-4">
                  <img
                    className="about-avocado img-fluid"
                    src={
                      resource.mainImage.asset.gatsbyImageData.images.fallback
                        .src
                    }
                    alt="Avocado"
                    style={{ height: "28vh", objectFit: "contain" }}
                  />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(resource.body || ""),
                  }}
                />
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default ResourcesTemplate

export const resourcesQuery = graphql`
  query ResourcesTemplateQuery($id: String!) {
    resource: sanityResources(id: { eq: $id }) {
      id
      title
      mainImage {
        asset {
          gatsbyImageData(
            fit: FILL
            placeholder: BLURRED
            sizes: ""
            layout: CONSTRAINED
          )
        }
      }
      body
    }
  }
`
